import './App.css';
import Home from './components/Home'
import ContactForm from './components/ContactForm'
import OfferForm from './components/OfferForm'
import Header from './components/Header'
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
function App() {
  useEffect(() => {
    console.log(`
 █████╗ ███████╗██╗██████╗ ███╗   ███╗ █████╗ 
██╔══██╗██╔════╝██║██╔══██╗████╗ ████║██╔══██╗
███████║█████╗  ██║██████╔╝██╔████╔██║███████║
██╔══██║██╔══╝  ██║██╔══██╗██║╚██╔╝██║██╔══██║
██║  ██║██║     ██║██║  ██║██║ ╚═╝ ██║██║  ██║
╚═╝  ╚═╝╚═╝     ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝╚═╝  ╚═╝
                                              
███████╗██╗███╗   ███╗ ██████╗ ███╗   ██╗     
██╔════╝██║████╗ ████║██╔═══██╗████╗  ██║     
███████╗██║██╔████╔██║██║   ██║██╔██╗ ██║     
╚════██║██║██║╚██╔╝██║██║   ██║██║╚██╗██║     
███████║██║██║ ╚═╝ ██║╚██████╔╝██║ ╚████║     
╚══════╝╚═╝╚═╝     ╚═╝ ╚═════╝ ╚═╝  ╚═══╝     
                                              
███████╗██╗    ███████╗██╗   ██╗██╗           
██╔════╝██║    ██╔════╝██║   ██║██║           
███████╗██║    █████╗  ██║   ██║██║           
╚════██║██║    ██╔══╝  ██║   ██║██║           
███████║██║    ██║     ╚██████╔╝██║           
╚══════╝╚═╝    ╚═╝      ╚═════╝ ╚═╝           
                                                                                                                                                                                                                                
          ( -_•)╦̵̵̿╤─
 `);
  })
  return (
    <div >
      <Router>
        <Header />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/contact' exact element={<ContactForm />} />
          <Route path='/offer' exact element={<OfferForm />} />
        </Routes>

      </Router>
    </div>
  );
}

export default App;
